import React from "react";
import imgone from "./images/Scientists_Row-1.png";
import imgtwo from "./images/Scientists_Row-2.png";
import imgthree from "./images/Scientists_Row-3.png";
import bgv from "./images/bgv.png";
import mobile_row from '../components/images/Scientists_Row_Mobile.png'
export default function What() {
  return (
    <div>
      <section id="what">
        <div id="what-inner">
          <div className="mobile-only">
            <h1 className="community-page-h1">Community First</h1>
            <p className="community-page-message">
              Mad Scientists is a project of METONYMY MACHINE x Kubilay Odabas.{" "}
              <br />
              We are fully committed to create a NFT project to last and live
              beyond the current hype!
              <br />
              The most important aspect for us is to be as transparent as
              possible. We will not make no empty promises. <br /> You can read
              our detailed Roadmap and details about our team in our Medium Page
              <a
                href="https://metonymymachine.notion.site/Mad-Scientists-Public-Roadmap-5470b99da56c422ca212b4988558abfc"
                className="c-notion-page"
              >
                here.
              </a>
            </p>
          </div>
          <h1 className="what-heading pt-2">What are Mad scientists?!</h1>

          <div className="what-row">
            <div className="what-row-col-1">
              <img src={bgv} className="bgv" alt="" />
            </div>
            <div className="what-row-col-2">
              <div className="col">
                <span className="what-plan-heading-row">
                  <div className="plane-one"></div>
                  <div className="what-content">
                    <h2 className="what-plane-equal-heading">Crypto Art</h2>
                    <p className="what-plan-equal-p">
                      The Mad Scientists are unique cryto artworks with over 200
                      hand-drawn illustrated features.
                    </p>
                  </div>
                </span>
                <hr className="hr-white desktop-only" />
              </div>

              <div className="col">
                <span className="what-plan-heading-row">
                  <div className="plane-two"></div>
                  <div className="what-content">
                    <h2 className="what-plane-equal-heading">
                      NFT as digital assets
                    </h2>
                    <p className="what-plan-equal-p">
                      {" "}
                      The Mad Scientists live on the blockchain and are pinned
                      to IPFS. This mean that they will always be accessible.
                      The owner of the NFT will not only hold the art but is
                      also entitled to the use of commercial rights (except for
                      TV/Cinema).
                    </p>
                  </div>
                </span>
                <hr className="hr-white desktop-only" />
              </div>

              <div className="col">
                <span className="what-plan-heading-row">
                  <div className="plane-three"></div>
                  <div className="what-content">
                    <h2 className="what-plane-equal-heading">Roadmap</h2>
                    <p className="what-plan-equal-p">
                      It is our strong belief to put "Community first". Owning a
                      Mad Scientist will put you in the middle of a vivid and
                      open community to everyone. As a team we are commited to
                      build a strong and long lasting project with
                      collaborations, airdrops and future functionalities. To be
                      fully transparent on the roadmap we have created a Notion
                      page that will be announced in the next days. You can also
                      join us in Discord and discuss many upcoming step directly
                      with us.
                    </p>
                  </div>
                </span>

                <a
                  href="https://metonymyuniverse.medium.com/ "
                  target="_blank"
                  className="link-redirect"
                >
                  <button className="home-discord-btn w-d btn mt-5 desktop-only">
                    Go to Medium
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="dude_wrapper">
            <img
              src={mobile_row}
              className="dude_one mobile-only"
              alt="madscientists-one"
            />
            <img
              src={imgone}
              className="dude_one desktop-only"
              alt="madscientists-one"
            />
            <img src={imgtwo} className="dude_two" alt="madscientists-two" />
            <img
              src={imgthree}
              className="dude_three"
              alt="madscientists-three"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
