import "./App.css";
import React from "react";
import Epochs from "./components/Epochs";
import FAQ from "./components/FAQ";
import Home from "./components/Home";
import Menu from "./components/Menu";
import Notmadenough from "./components/Notmadenough";
import Rarities from "./components/Rarities";
import What from "./components/What";
import Why from "./components/Why";
import Timer from "./components/Timer";
import Community from './components/Community'
import MainMintingPage from './components/MainMintingPage'
import UI from "./components/UI";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import IntermediatePage from "./components/IntermediatePage";
function App() {
  document.title ="Mad Scientists"; 
  document.getElementsByTagName("META")[2].content="Your description about the page or site here to set dynamically";


  return (
    <div className="App" id="main">
     
{/*    This is inside Router due to having dynamic routing   */}

<Router>
  <Switch>
    <Route exact path='/mad-mint' component={Home}/>
    <Route exact path='/minting-page' component={Home} />
      <Route exact path='/' component={Home}/>
  </Switch>
</Router>

    </div>
  );
}

export default App;
