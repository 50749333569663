import React, { useState, useEffect } from "react";
import twitter from "./images/twitter.svg";
import discord from "./images/discord.svg";
import scientist from "./images/mad-main.svg";
import gif from "./images/doc.png";
import opensea from "./images/opensea.svg";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import {
  preSaleMint,
  loadCurrentSupply,
  getCurrentWalletConnected,
} from "../scripts/Web3methods";
import Menu from "./Menu";
import What from "./What";
import Why from "./Why";
import Epochs from "./Epochs";
import Rarities from "./Rarities";
import FAQ from "./FAQ";
import Timer from "./Timer";
import { Link } from "react-router-dom";
import Community from "./Community";
export default function Home() {
  const [minNum, setMintNum] = useState(1);
  const [varient, setVarient] = useState("");
  const [status, setStatus] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [supply, setSupply] = useState(""); //default message
  // PreSale Mint Function
  const onPreSaleMintPressed = async () => {
    const { status } = await preSaleMint(minNum);
    setStatus(status);
    console.log(status);
    console.log(minNum);
  };

  //Hooks - useEffect (1x rendered)

  // useEffect(async () => {
  //   const { address, status } = await getCurrentWalletConnected();

  //   const supply = await loadCurrentSupply();
  //   setSupply(supply);
  //   setStatus(status);
  //   addWalletListener();
  //   console.log(status, "from home");
  // }, []);

  //Hooks - useEffect (1x rendered)
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://metamask.io/download.html`}
          >
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  // console.log(status, "from home");

  // const minus = (e) => {
  //   if (minNum < 1) {
  //     setMintNum(1);
  //   }
  //   if (minNum > 1) {
  //     setMintNum(minNum - 1);
  //   }
  // };

  // const plus = (e) => {
  //   if (minNum > 12) {
  //     setMintNum(10);
  //   }
  //   if (minNum < 12 && minNum > 0) {
  //     setMintNum(minNum + 1);
  //   }
  // };

  //  const deadline = (endtime) => {
  //    const total = Date.parse(endtime) - Date.parse(new Date());
  //    const seconds = Math.floor((total / 1000) % 60);
  //    const minutes = Math.floor((total / 1000 / 60) % 60);
  //    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  //    const days = Math.floor(total / (1000 * 60 * 60 * 24));

  //    return {
  //      total,
  //      days,
  //      hours,
  //      minutes,
  //      seconds,
  //    };
  //  };
  //  let date = new Date("October 8 2021 16:00:00").toISOString();

  return (
    <div>
      <Menu />
      {/* when removing timer this will cause menu height to be decreased to fix that add a class mt-0 to the home */}
      {/* <Timer /> */}

      <section id="home" className="mt-0">
        <div id="home-inner ">
          {status && (
            <div className="alert alert-warning desktop-only  m-auto open-sans">
              {status}
            </div>
          )}
          <div className="mobile-only">
            {/*<button className="metamask-btn  mb-4">CONNECT METAMASK</button>*/}
          </div>
          <div className="mobile-only mobile-social-icons">
            <a
              href="https://twitter.com/MADSCIENTISTS__"
              target="_blank"
              className="link-redirect"
            >
              <img
                src={twitter}
                className="twitter-mobile"
                height="43px"
                width="29px"
                alt=""
              />
            </a>
            <a
              href="https://discord.gg/kAmSxtS5wD"
              target="_blank"
              className="link-redirect"
            >
              <img src={discord} height="43px" width="29px" alt="" />
            </a>
          </div>
          {/* <div className="timer pt-4 ">
            <div className="timmer-inner">
              <p>
                Launching in{" "}
                {deadline(date).days < 10
                  ? "0" + deadline(date).days
                  : deadline(date).days}{" "}
                :{" "}
                {deadline(date).hours < 10
                  ? "0" + deadline(date).hours
                  : deadline(date).hours}{" "}
                :{" "}
                {deadline(date).minutes < 10
                  ? "0" + deadline(date).minutes
                  : deadline(date).minutes}
              </p>
            </div>
          </div> */}
          <h1 className="home-h1 desktop-only ">MAD SCIENTISTS</h1>
          <h2 className="home-h2 desktop-only">Why you mad Doc?!</h2>
          {/* <button className="home-minting-btn ">Minting very Soon</button>{" "} */}
          <br />
          {/* <span className="home-pricing-text">
            {" "}
            7.777 / 7.777 available{" "}
          </span>{" "}
      
          <br />
          <span className="home-gas-text">Price is tba in ETH</span> */}

          <img
            src={gif}
            className="mobile-only  mb-3 img-fluid"
            width="350px"
            alt="IMG"
          />
          {/* <div className="mobile-only">
            <div className="checklists">
              <div className="second-list  d-flex ">
                <div className="text">
                  <i
                    className="fas fa-check "
                    style={{
                      padding: "5px",
                      background: "#EF5DA8",
                      color: "rgb(255,237,183)",
                    }}
                  ></i>
                </div>
                <div>
                  Public-sale price is 0.065 ETH <br /> (Max 12 per transaction)
                </div>
              </div>
            </div>
          </div> */}
          <br className="mobile-only" />
          <a
            href="https://discord.gg/kAmSxtS5wD"
            target="_blank"
            className="link-redirect"
          ></a>
          <div className="home-gap"></div>
          <br />
          <div className="mad-main-flex desktop-only">
            <div className="mad-main-one">
              <img src={gif} width="272px" height="272px" alt="IMG" />
            </div>
            <div className="mad-main-two">
              <br />
              <span className="home-minting-input">
                <a href="https://discord.com/invite/WfYa9xwZf2" target="_blank">
                  <button>Join Discord</button>
                </a>
                <br />
              </span>
              {/* <span className="home-pricing-text">
                {" "}
                {supply === "" ? "Loading..." : 7777 - supply} / 7777{" "}
              </span>{" "} */}
              {/* <br />
              <span className="home-gas-text">
                Available <br />{" "}
              </span> */}
              {/* <div className="checklists desktop-only">
                <div className="second-list  d-flex ">
                  <div className="text">
                    <i
                      className="fas fa-check "
                      style={{
                        padding: "5px",
                        background: "#EF5DA8",
                        color: "rgb(255,237,183)",
                      }}
                    ></i>
                  </div>
                  <div>
                    Public-sale price is 0.065 ETH <br /> (Max 12 per
                    transaction)
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className=" mt-0 pb-5 checklists">
            <div></div>
            <div></div>
            <div className=" first-list d-flex  ">
              <div className="text">
                <i
                  className="fas fa-check "
                  style={{
                    padding: "5px",
                    background: "#EF5DA8",
                    color: "rgb(255,237,183)",
                  }}
                ></i>
              </div>
              <div>
                24 hour pre-sale price is 0.055 <br /> ETH (Max 7 per
                transaction) <br />
                <div style={{ marginBottom: "2rem" }}></div>
                There will be only 1.777 <br /> Mad Scientists available <br /> during
                pre-sale.
              </div>
            </div>
            <div className="second-list  d-flex ">
              <div className="text">
                <i
                  className="fas fa-check "
                  style={{
                    padding: "5px",
                    background: "#EF5DA8",
                    color: "rgb(255,237,183)",
                  }}
                ></i>
              </div>
              <div>
                Public-sale price is 0.065 ETH <br /> (Max 12 per transaction)
              </div>
            </div>

            <div></div>
            <div></div>
          </div> */}

          {/* <div
            className="sale-info-banner  "
            style={{ backgroundColor: "rgb(255,111,119)" }}
          >
            Pre-Sale starts: October 8th, 2pm UTC
          </div> */}
          <div className="minting-ended-container">
            <h1 className="h-minting-ended-heading ">MINTING HAS ENDED.</h1>
            <h2 className="h-minting-ended-subheading">
              See secondary market on Opensea
            </h2>{" "}
            <br />
            <a href="https://opensea.io/collection/madscientists" target='_blank'>
              <img src={opensea} alt="IMG" className="opensea-img" />
            </a>
          </div>
        </div>
      </section>
      <Community />
      <What />
      <Why />
      <Epochs />
      <Rarities />
      <FAQ />
    </div>
  );
}
