import React from "react";
import headgear from "./images/rare_stuff/headgear.svg";
import eyes from "./images/rare_stuff/eyes.svg";
import mouth from "./images/rare_stuff/mouth.svg";
import glasses from "./images/rare_stuff/glasses.svg";
import outfit from "./images/rare_stuff/outfit.svg";
export default function Rarities() {
  return (
    <div>
      <section id="rarities" className="rarities-main">
        <div id="rarities-inner">
          <div className="main-text">
            <div className="mobile-only">
              <h1 className="r-h1-mobile">7.777 MAD SCIENTISTS.</h1>
              <h2 className="r-h2-mobile">
                six Different Epochs. Many Rarities.
              </h2>
            </div>
            <h1 className="r-h1 desktop-only">Rarities</h1>
            <h2 className="r-h2 desktop-only">
              Rarities - Over 180 hand illustrated layers - randomly generated.
            </h2>
          </div>

          <div className="rarities-holder">
            <div className="r-variations">
              <div className="r-one">
                <h4 className="r-varitaion-text desktop-only">_TYPES</h4>
                <div className="r-flex-mobile">
                  <h2
                    className="r-varitaion-text mobile-only"
                    style={{ marginLeft: "15px" }}
                  >
                    _TYPES
                  </h2>
                  <div>
                    <img
                      src={headgear}
                      className="mobile-only"
                      width="150px"
                      height="109px"
                      alt="IMG"
                    />
                  </div>
                </div>
                <div className="r-text-input pt-3">
                  <p>116</p>
                  <p>Total Mad Scientists</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Ancient </p>
                  <p>Less common</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>80's</p>
                  <p>Common</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Current</p>
                  <p>Not so common</p>
                </div>

                <div className="r-text-input-white pt-3 mt-3">
                  <p>Gothic </p>
                  <p>Not so common</p>
                </div>

                <div className="r-text-input-white pt-3 mt-3">
                  <p>Renaissance</p>
                  <p>Rare</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Future</p>
                  <p>Super Rare</p>
                </div>
              </div>

              <div className="r-two ">
                <h4 className="r-varitaion-text desktop-only">_VARIATIONS</h4>
                <h4
                  className="r-varitaion-text mobile-only"
                  style={{ marginLeft: "15px" }}
                >
                  _VARIATIONS
                </h4>
                <div className="r-text-input pt-3">
                  <p className="">Total Layers</p>
                  <p className="r-input-num">193</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Background</p>
                  <p className="r-input-num">7</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Eyes</p>
                  <p className="r-input-num">21</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Mouth</p>
                  <p className="r-input-num">38</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Accessories </p>
                  <p className="r-input-num">3</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Head</p>
                  <p className="r-input-num">33</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Outift</p>
                  <p className="r-input-num">39</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Glasses</p>
                  <p className="r-input-num">13</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Headgear</p>
                  <p className="r-input-num">9</p>
                </div>
                <div className="r-text-input-white pt-3 mt-3">
                  <p>Mutation</p>
                  <p className="r-input-num">2</p>
                </div>
              </div>
            </div>
            <img className="r-outfit" src={outfit} alt="IMG" />
            <img className="r-mouth desktop-only" src={mouth} alt="IMG" />
            <img className="r-eyes desktop-only" src={eyes} alt="IMG" />
            <img className="r-glasses desktop-only" src={glasses} alt="IMG" />
            <img className="r-headgear desktop-only" src={headgear} alt="IMG" />
          </div>
        </div>
      </section>
    </div>
  );
}
