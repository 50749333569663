import React from "react";

export default function Community() {
  return (
    <div className="community-page desktop-only">
      <div className="community-page-inner">
        <h1>Community First</h1>
        <p className="community-page-message">
          Mad Scientists is a project of METONYMY MACHINE x Kubilay Odabas.{" "}
          <br />
          We are fully committed to create a NFT project to last and live beyond
          the current hype!
          <br />
          The most important aspect for us is to be as transparent as possible.
          We will not make no empty promises. <br /> You can read our detailed
          Roadmap and details about our team in our Medium Page
          <a
            href="https://metonymymachine.notion.site/Mad-Scientists-Public-Roadmap-5470b99da56c422ca212b4988558abfc"
            className="c-notion-page"
          >
            here.
          </a>
        </p>
        <div className="home-m-cards mt-5">
          <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>{" "}
            <br />
            <p> Verified smart-contract & ERC-721 tokens</p>
          </div>
          <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p>5% go to charity from all primary sales</p>
          </div>
          <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p>5% of primary sales to further development</p>
          </div>
          {/* <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p>Rarity Sniper & Rarity Tools Integration</p>
          </div> */}
          <div className="home-m-card-item ">
            <i
              className="fas fa-check home-m-card-item-5"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p> Discord bot for all listings & sales</p>
          </div>
        </div>
        <div className="home-m-cards desktop-only mt-4">
          <div className="home-m-card-item ">
            <i
              className="fas fa-check home-m-card-item-6"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p> Max. 12 x mintings possible per transaction</p>
          </div>
          <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p>Reveal 48 hours after sale</p>
          </div>
          <div className="home-m-card-item commercial-rights">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p>Entitled to commercial rights (see exceptions)</p>
          </div>
          <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p className="p-center">Generative & random creation</p>
          </div>
          <div className="home-m-card-item">
            <i
              className="fas fa-check"
              style={{
                padding: "5px",
                backgroundColor: "royalblue",
                color: "rgb(255,237,183)",
              }}
            ></i>
            <br />
            <p>High diversification of rarity categories</p>
          </div>
        </div>
        <a
          href="https://discord.gg/bs5Gy88T"
          target="_blank"
          className="link-redirect"
        >
          <button className="home-discord-btn btn mt-3 desktop-only">
            Join Discord
          </button>
        </a>
      </div>
    </div>
  );
}
