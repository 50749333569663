import React,{useEffect, useState} from "react";
import twitter from "./images/twitter.svg";
import discord from "./images/discord.svg";
import { Link } from "react-router-dom";

import {
  connectWallet,
  getCurrentWalletConnected, 
  mint,
  preSaleMint, 
  preSaleStart, 
  preSaleStop, 
  saleStart, 
  saleStop, 
  loadCurrentSupply,
  loadPreSaleStatus,
  loadSaleStatus,
  withdrawAll,
  loadBalanceContract
} from '../scripts/Web3methods'
export default function Menu() {
  //Hooks - useStates
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const openTwitter = () => {
    console.log("clicked");
    function twitter() {
      window.open("https://twitter.com/MADSCIENTISTS__", "_blank");
    }
  };

  useEffect(()=>{
  (async()=>{
    const walletResponse = await connectWallet();
    //setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  })();
  },[connectWallet]);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    //setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

 

  console.log(status,'from menu');

  return (
    <div>
      <header className="desktop-only">
        <nav className="menu ">
          <ul className="menu-items">
            <div>
              {" "}
              <a
                target="_blank"
                href="https://twitter.com/MADSCIENTISTS__"
                className="link-redirect"
              >
                <img
                  onClick={openTwitter}
                  style={{ cursor: "pointer", marginRight: "15px" }}
                  src={twitter}
                  height="30vh"
                  width="30vw"
                  alt="IMG"
                />
              </a>
              <a
                href="https://discord.gg/kAmSxtS5wD"
                target="_blank"
                className="link-redirect"
              >
                {" "}
                <img
                  src={discord}
                  style={{ cursor: "pointer" }}
                  height="30vh"
                  width="30vw"
                  alt="IMG"
                />
              </a>
            </div>
            <div></div>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#what">What?</a>
            </li>
            <li>
              <a href="#whytheymad">Why?</a>
            </li>
            <li>
              <a href="#epochs">Epochs</a>
            </li>
            <li>
              <a href="#rarities">Rarities</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
            <div></div>
            <div>
              {/* <button className="metamask-btn btn">CONNECT METAMASK</button> */}
              {/* <button
                className="metamask-btn btn"
                id="walletButton"
                onClick={connectWalletPressed}
              >
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button> */}
            </div>
          </ul>
        </nav>
      </header>

      {/* Mobile navbar */}

      <nav className="mobile-only navbar pb-0 navbar-expand-lg  " >
        <div className="container-fluid">
          <a className="navbar-brand" href="#"></a>
          <button
            className="menu-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#what">
                  What
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#whytheymad">
                  Why
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#rarities">
                  Rarities
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="alert alert-danger w-100 mt-2 mobile-only  m-auto open-sans" >
          Please Use Desktop For Minting
        </div>
      </nav>
    </div>
  );
}
