import React from "react";
import why1 from "./images/MadScientists_Keyvisual_1.jpg";
import why2 from "./images/MadScientists_Keyvisual_2.jpg";
export default function Why() {
  return (
    <div>
      <section id="whytheymad">
        <div id="why-inner">
          <h1 className="why-h1">Why they mad?!</h1>
          <p className="why-main-text ">
            We are storytellers and our scientists have a strong story that we
            will continiously build upon. Our scientists were "born" in
            different epochs and they all had just one goal: They wanted to
            built a time-machine to escapce the now. However, none could achieve
            this goal, except a few super rare Mad Scientists.
            <div className="mobile-only">
              <a href="http://medium.com/@MadScientistsNFT" target="_blank">
                <button className="btn-article mt-5 mb-5">Full Article</button>
              </a>
            </div>
          </p>
          <div className="why-flex desktop-only">
            <div className="img-1">
              <img className="why-img mb-5" src={why1} alt="IMG" />
              <p className="article article-one">
                <span className="why-i-sinned">The Sinner</span> <br />
                <p className="article mt-3">
                  {" "}
                  He could no longer endure this constant state of torture.
                  Scientist he was, he became a scientist of his own evil.
                  Nightly labour commenced. He began experimenting on himself,
                  aiming to purge, to purify his soul, to extract from it the
                  shameful traits it secretly possessed. Spectacular, if mad,
                  treatment was found. He succeeded in creating a powerful
                  potion, a means of transformation, a chemical portal, a liquid
                  time machine. (..)
                </p>
                <br />
                <a
                  href="https://madscientistsnft.medium.com/the-sinner-7a961638a696"
                  target="_blank"
                >
                  <button className=" btn-article mt-4 mb-4">
                    Full Article
                  </button>
                </a>
              </p>
            </div>
            <div className="img-2">
              <img className="why-img mb-5" src={why2} alt="IMG" />
              <p className="article">
                <span className="why-i-sinned">The Renaissance Man</span> <br />
                <p className="article mt-3">
                  {" "}
                  Imagine an evil twin of Leonardo da Vinci—an exquisite talent
                  walking the thin line between endeavour and hybris, madness
                  even. There’s nothing he isn’t capable of. He paints like a
                  god, naturally. A master engineer without equal, he is eager
                  to serve civil and bellicose causes. His alliances with the
                  rulers and tyrants of his age and day are as numerous as they
                  are amuous. (..)
                </p>{" "}
                <br />
                <a
                  href="https://madscientistsnft.medium.com/the-renaissance-man-e8999c5fa44f"
                  target="_blank"
                >
                  <button className=" btn-article mt-4 mb-4">
                    Full Article
                  </button>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
