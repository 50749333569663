import React from "react";
import current from "./images/current.jpg";
import futurist from "./images/mad-future-01.jpg";
import gothic from "./images/mad-gothic01.png";
import renaissance from "./images/mad-renais01.png";
import eighties from "./images/mad-80s-01.jpg";
import ancient from "./images/mad-ancient01.png";
export default function Epochs() {
  return (
    <div>
      <section id="epochs">
        <div id="epoch-inner">
          <h1 className="epoch-h1">MAD SCIENTISTS.</h1>
          <h2 className="pb-4 epoch-h2">
            Six different epochs. Many rarities.
          </h2>

          <div className="row ">
            <div className="col-lg-4 col mb-md-3 col-md-6 col-sm-12 ">
              <div className="card w-100">
                <div className="card-body w-100">
                  <img
                    src={ancient}
                    className="epoch-placeholder"
                    alt="placeholder"
                  />
                  <br />
                  <p className="p-tag">Ancient Mad Scientist</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-md-3 col col-md-6 col-sm-12">
              <div className="card w-100">
                <div className="card-body w-100">
                  <img
                    src={renaissance}
                    className="epoch-placeholder"
                    alt="placeholder"
                  />
                  <br />
                  <p className="p-tag">Renaissance Mad Scientist</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col col-md-6 col-sm-12 mb-4">
              <div className="card w-100">
                <div className="card-body w-100">
                  <img
                    className="epoch-placeholder"
                    alt="placeholder"
                    src={gothic}
                  />
                  <br />
                  <p className="p-tag">The Gothic Mad Scientist</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col col-md-6 col-sm-12">
              <div className="card w-100">
                <div className="card-body w-100">
                  <img
                    src={eighties}
                    className="epoch-placeholder"
                    alt="placeholder"
                  />
                  <br />
                  <p className="p-tag">The 80s Mad Scientist</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col col-md-6 col-sm-12">
              <div className="card w-100">
                <div className="card-body w-100">
                  <img
                    src={current}
                    className="epoch-placeholder"
                    alt="placeholder"
                  />
                  <br />
                  <p className="p-tag">The Current Mad Scientist</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col col-md-6 col-sm-12">
              <div className="card w-100">
                <div className="card-body w-100">
                  <img
                    src={futurist}
                    className="epoch-placeholder"
                    alt="placeholder"
                  />
                  <br />
                  <p className="p-tag">The Future Mad Scientist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
