import React from "react";
import vector from "./images/icon1.svg";
import vector1 from "./images/icon2.svg";
import x from "./images/x.svg";
import l_icon from "./images/little_icon.svg";
import kub from "./images/kubilay.svg";
export default function FAQ() {
  return (
    <div>
      <section id="faq" className="mt-5">
        <div id="faq-inner">
          <div className="faq-h1">FAQ</div>
          <div className="faq-h2">How does this work, you wonder?</div>
          <div className="faq-container accordion" id="faqcontainer">
            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#eight"
              role="button"
              aria-expanded="false"
              aria-controls="eight"
            >
              <strong> How are the Mad Scientists generated? </strong>
              <div
                className="collapse"
                id="eight"
                data-bs-parent="#faqcontainer"
              >
                <div className=" w-100 collopse-text">
                  Mad Scientists are a collaboration between MΞTONYMY MACHINΞ
                  GmbH and illustrator Kubilay Odabas. We have combined the
                  super powers of high quality illustrations with a generative
                  process to make sure that all Mad Scientists are unique and
                  awesome!
                </div>
              </div>
            </div>
            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#six"
              role="button"
              aria-expanded="false"
              aria-controls="six"
            >
              <strong>
                What has a “Time Machine Ticket” to do with Mad Scientists?
              </strong>
              <div className="collapse" id="six" data-bs-parent="#faqcontainer">
                <div className=" w-100  collopse-text">
                  The Time Machine Ticket is connected to the Mad Scientists on
                  a narrative. Our Mad Scientists try to build a time machine.
                  And the Ticket could be the answer! The Time Machine Ticket is
                  a ticket with a utility that will only be revealed when the
                  time has come. We will only ever sell 20% of the Time Machine
                  Tickets. 80% will be given through Giveaways and Art Contests
                  to the growing community of Mad Scientists. Also Time Machine
                  Tickets will be eligible to some future airdrops. Expect great
                  things to happen in the future!
                </div>
              </div>
            </div>
            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#seven"
              role="button"
              aria-expanded="false"
              aria-controls="seven"
            >
              <strong> Where can I mint my Mad Scientists? </strong>
              <div
                className="collapse"
                id="seven"
                data-bs-parent="#faqcontainer"
              >
                <div className=" w-100  collopse-text">
                  You can mint on October 8th on https://www.mad-scientists.xyz
                  during the presale and starting October 9th during the public
                  sale.
                </div>
              </div>
            </div>

            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#blockchain"
              role="button"
              aria-expanded="false"
              aria-controls="blockchain"
            >
              <strong>On which Blockchain are the Mad Scientists?</strong>
              <div className="collapse" id="blockchain" data-bs-parent="#faqcontainer">
                <div className=" w-100  collopse-text">
                  Mad Scientists are on ETHEREUM and erc721 token.
                </div>
              </div>
            </div>

            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#one"
              role="button"
              aria-expanded="false"
              aria-controls="one"
            >
              <strong>Can I mint on mobile?</strong>
              <div className="collapse" id="one" data-bs-parent="#faqcontainer">
                <div className=" w-100  collopse-text">
                  No, you will need to use a Desktop Computer.
                </div>
              </div>
            </div>
            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#two"
              role="button"
              aria-expanded="false"
              aria-controls="two"
            >
              <strong>What about Gas War?!</strong>
              <div className="collapse" id="two" data-bs-parent="#faqcontainer">
                <div className=" w-100  collopse-text">
                  We are very aware of gas wars which refere to spikes of gas
                  prices during the minting of a project. We recommend checking
                  the "Gas Estimator" at Blocknative and being familiar with the
                  mechanics of minting in general. We are also taking some
                  measures on our part: <br />
                  1-) We will make sure that our verified smart-contract is not
                  public until the pre-sale starts. <br />
                  2-) There is a limit of 7 x Mints per Wallet during pre-sale.
                  And a maximum of 12 x Mints per Transaction during the public
                  sale. <br />
                  3-) We will have a upstreamed 20second waiting page before you
                  reach our mint page.
                </div>
              </div>
            </div>
            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#three"
              role="button"
              aria-expanded="false"
              aria-controls="three"
            >
              <strong>When will you launch?</strong>
              <div
                className="collapse"
                id="three"
                data-bs-parent="#faqcontainer"
              >
                <div className=" w-100  collopse-text">
                  Our pre-sale starts on October 8th, 2pm UTC. You do not need
                  to whitelist. But we will share our secret mint-link on our
                  discord 30 minutes earlier.
                </div>
              </div>
            </div>

            

            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#four"
              role="button"
              aria-expanded="false"
              aria-controls="four"
            >
              <strong>I have another question. How can I contact you?</strong>
              <div
                className="collapse"
                id="four"
                data-bs-parent="#faqcontainer"
              >
                <div className=" w-100  collopse-text">
                  Please join our Discord to get in touch with our community
                  manager or other team members.
                </div>
              </div>
            </div>

            <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#last"
              role="button"
              aria-expanded="false"
              aria-controls="last"
            >
              <strong>How can I be sure that this is not a rug pull?</strong>
              <div
                className="collapse"
                id="last"
                data-bs-parent="#faqcontainer"
              >
                <div className=" w-100  collopse-text">
                  Behind Mad Scientists is the German NFT native startup
                  Metonymy Machine in collaboration with our artistis partner
                  Kubilay Odabas. We are building our projects with dedicated
                  teams and a 100% commitment to the community! As a German
                  entity we are bound to German law and operate fully
                  transparent. Our team is a mix of visual creators, artists,
                  developers, social media managers and NFT experts. Our
                  founders have each more than 15 years of experience and high
                  reputation in Germany. We believe in the cause that NFTs are
                  the future of creator economy. If you invest into our project
                  we are thrilled you are part of our community. Of course no
                  single individual in this space can guarantee the success of a
                  project. But as a brand and as a team we can only guarantee
                  that we will keep on working and innovating in this space.
                </div>
              </div>
            </div>

            {/* <div
              className="single-faq-box"
              data-bs-toggle="collapse"
              href="#five"
              role="button"
              aria-expanded="false"
              aria-controls="five"
            >
              <strong>How can I be sure that this is not a rug pull?</strong>
              <div
                className="collapse"
                id="five"
                data-bs-parent="#faqcontainer"
              >
                <div className=" w-100  collopse-text">
                We are a highly commited team of artists, developers and marketing experts creating NFT projects. We have founded Metonymy Machine GmbH as a German company based in Berlin and have been in talks with regulators and investors. Anything can happen throughout a long project, except a rug pull!   
                </div>
              </div>
            </div> */}
          </div>

          <div className="faq-goodbyes">
            <div className="outro">
              <b>“Mad Scientists”</b> is a project by METONYMY MACHINE GmbH - a
              metaverse company from Berlin. <br />
              <br />
              We are building to stay .
            </div>
            <div className="faq-journey">
              <a href="https://twitter.com/MADSCIENTISTS__" target="_blank">
                <img
                  src={vector}
                  style={{ marginRight: "1rem", cursor: "pointer" }}
                  height="13px"
                  width="13px"
                  alt=""
                />
              </a>
              <a
                href="https://www.instagram.com/metonymy_machine/"
                target="_blank"
              >
                <img
                  src={vector1}
                  style={{ cursor: "pointer" }}
                  height="13px"
                  width="13px"
                  alt=""
                />
              </a>
              <br />
              Join the journey
            </div>
          </div>
          <hr className="faq-breakline" />
          <div className="footer">
            <div></div>
            <div></div>
            <div
              className="copyright desktop-only"
              style={{ cursor: "pointer" }}
            >
              Copyright © 2021
            </div>
            <div className="signature">
              <img src={l_icon} height="100px" width="100px" alt="" />
              <img
                src={x}
                height="20px"
                width="20px"
                style={{ marginRight: "1.5rem" }}
                alt="IMG"
              />
              <img src={kub} height="50px" width="50px" alt="" />
              <br />
              A colaboration of <br />
              MΞTONYMY MACHINΞ <br />
              with Kubilay Odabas
            </div>
            <div className="privacy">
              <a
                href="https://www.metonymy-machine.com/privacy-policy/"
                className="p-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.metonymy-machine.com/terms-of-use/"
                className="terms-of-use"
                target="_blank"
              >
                Terms of Use
              </a>
              <br />
              <div className="d-left">
                <a
                  href="https://www.metonymy-machine.com/refund-policy/"
                  target="_blank"
                  className="p-policy "
                  style={{ cursor: "pointer" }}
                >
                  Refund Policy
                </a>
                <a
                  href="https://www.metonymy-machine.com/imprint/"
                  target="_blank"
                  className="terms-of-use"
                  style={{ cursor: "pointer" }}
                >
                  Imprint
                </a>
              </div>
            </div>
            <div
              className="copyright mobile-only mt-2 mb-2"
              style={{ cursor: "pointer" }}
            >
              Copyright © 2021
            </div>

            <div></div>
            <div></div>
          </div>
        </div>
      </section>
    </div>
  );
}
